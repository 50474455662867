<template>
  <!-- <div><img src="" alt=""></div> -->
  <div @click="isPanic" class="panic-button"><button class="button text-theme-2 btn-lg">Panic !</button></div>
</template>

<script>
export default {
  name: 'PanicButton',
  methods:{

    isPanic:function(){

      this.$emit('panic')

    }

  }

}
</script>

