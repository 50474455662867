<template>
  <div className="content" v-show="!isPanic">
    <h1 class="title text-theme-1 is-hidden">Futanari Site</h1>
    <h2 class="subtitle text-theme-1 is-hidden">For futanari lovers</h2>
    <PanicButton @panic="receivePanic"></PanicButton>
    
    <NavBar></NavBar>
    
    <br>
    <br>
    <router-view />
  </div>

</template>

<script>


import NavBar from '@/components/NavBar.vue'
import PanicButton from '@/components/PanicButton.vue'
import Swal from 'sweetalert2'

export default {
  components: {
    NavBar,
    PanicButton
  },
  data() {
    return { isPanic: false }

  },
  computed: {
    isAdult() {
      return this.$store.getters.checkIsAdult

    }

  },
  mounted() {
    this.showAlert()
  },
  methods: {
    receivePanic() {
      this.isPanic = true

      document.title = 'Google'

      setTimeout(function () {

        window.location.href = "//www.google.com"

      }, 1000)

    },
    userIsAdult() {
      this.$store.commit('isAdult', true);

    },
    showAlert() {

      if (!this.isAdult) {
        Swal.fire({
          title: "Waning",
          text: "You need to be over 18 to see this content.",
          icon: "warning",
          showCancelButton: true,
          allowOutsideClick: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, I'm 18 years old!"
        }).then((result) => {
          if (result.isConfirmed) {

            this.userIsAdult()
            // Swal.fire({
            //   title: "I'm 18 years old",
            //   text: "Your file has been deleted.",
            //   icon: "success"
            // });
          } else {

            window.location.href = "//www.google.com"

          }
        });

      }


    }

  }


}


</script>

