<template>
    <div class="about my-5">
        <div class="container">
            <iframe style='background-color: transparent;' width="100%" height="100vh" scrolling="no" frameborder="0" allowtransparency="true" marginheight="0" marginwidth="0" name="spot_id_10002800" src="https://a.adtng.com/get/10002800?ata=maxpet01"></iframe>
            <div class="columns">
                <div v-if="image" class="column has-text-centered">
                    <img :src="`${apiUrl}/uploads/gallery/${id}/original/${image.name}`" alt="">
                </div>

                <div v-else class="column has-text-centered">
                    <img class="img-fluid" src="images/loading.gif" alt="">
                </div>
            </div>
            <iframe style='background-color: transparent;' width="100%" height="100vh" scrolling="no" frameborder="0" allowtransparency="true" 
            marginheight="0" marginwidth="0" name="spot_id_10001820" src="https://a.adtng.com/get/10001820?ata=maxpet01"></iframe>
        </div>
    </div>

</template>

<script>

export default {

    data() {

        return {
            id: null,
            image: null


        }

    },

    created() {
        this.id = this.$route.params.id

        let self = this
        this.getImage(this.$store.getters.apiUrl, this.id).then(function (i) {


            self.image = i


        })


    },
    mounted() {

        // this.loadScript('https://js.wpadmngr.com/static/adManager.js');

        //     console.log('teste')
        //     let recaptchaScript = document.createElement('script')
        //   recaptchaScript.setAttribute('src', 'https://js.wpadmngr.com/static/adManager.js')
        //   recaptchaScript.setAttribute('data-admpid', '181547')
        //   recaptchaScript.setAttribute('async', 'async')
        //   document.head.appendChild(recaptchaScript)

    },
    watch: {
        '$route'(to) {
            // Reage às mudanças na rota
            this.id = to.params.id


        }
    },
    computed: {

        apiUrl() {

            return this.$store.getters.apiUrl

        }
    },
    methods: {

        getImage: async (host, id) => {

            let options = {
                headers: {
                    'Content-Type': 'appication/json;charset=utf-8',
                    'Access-Control-Allow-Origin': '*',
                },
                method: 'get',
            };

            let response = await fetch(`${host}/api/images/download/${id}`, options);
            return await response.json();

        },
        loadScript(src) {
            return new Promise((resolve, reject) => {
                const script = document.createElement('script');
                script.async = true;  // Adiciona o atributo async
                script.src = src;
                script.setAttribute('data-admpid', '210097');
                script.onload = () => resolve();
                script.onerror = () => reject(new Error(`Falha ao carregar o script ${src}`));
                document.head.appendChild(script);
            });
        }


    }





}

</script>
