import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import DownloadView from '../views/DownloadView.vue'
import DiscordServers from '../views/DiscordServers.vue'
// import ComunitiesView from '../views/ComunitiesView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { ogImage: 'https://futanari.site/img/icons/playstore.png' }
  },
  {
    path: '/download/:id',
    name: 'download',
    component: DownloadView

  },
  {
    path: '/servers',
    name: 'servers',
    component: DiscordServers

  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

// router.beforeEach((to, from, next) => {

//   // updateMetaTags(to);

//   next()


// })


// function updateMetaTags(to) {


//   async function getImage(id) {

//     let options = {
//       headers: {
//         'Content-Type': 'appication/json;charset=utf-8',
//         'Access-Control-Allow-Origin': '*',
//       },
//       method: 'get',
//     };

//     let response = await fetch(`https://data.futanari.site/api/images/download/${id}`, options);
//     return await response.json();

//   }


//   getImage(to.params.id).then(function (i) {

//     let image = `https://data.futanari.site/uploads/gallery/${i.id}/original/${i.name}`;

//     // ogImage = image
//     let ogImageTag = document.getElementById('og-image');
//     if (!ogImageTag) {
//       ogImageTag = document.createElement('meta');
//       ogImageTag.setAttribute('property', 'og:image');
//       document.head.appendChild(ogImageTag);
//     }
//     // Atualiza o conteúdo da tag meta
//     ogImageTag.setAttribute('content', image);


//   })

// }

export default router
