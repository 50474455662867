<template>
    <div className="interact-menu">
        <button class="button" @click="loveImage">
            <i :class="loved ? 'fa-solid' : 'fa-regular'" class="fa-heart"></i>
        </button>
        <button class="button" @click="downloadPage">
            <i  class="fas fa-download"></i>
        </button>
    </div>

</template>

<style></style>

<script>

export default {
    props: ['image'],
    data() {
        return {
            loved: false
        }
    },
    methods: {
        loveImage() {

            let lovedImages = JSON.parse(localStorage.getItem('lovedImages'))

            if (this.loved) {

                let index = lovedImages.indexOf(this.image)

                if (index > -1) { // only splice array when item is found
                    lovedImages.splice(index, 1); // 2nd parameter means remove one item only
                    localStorage.setItem('lovedImages', JSON.stringify(lovedImages))
                    this.loved = false
                }


            }else{
                lovedImages.push(this.image)
                localStorage.setItem('lovedImages', JSON.stringify(lovedImages))
                this.loved = true

            }



        },
        checkIsLoved() {
            let lovedImages = JSON.parse(localStorage.getItem('lovedImages'))

            if (lovedImages.includes(this.image)) {

                this.loved = !this.loved

            }




        },
        createStorage() {

            if (!localStorage.getItem('lovedImages')) {
                localStorage.setItem('lovedImages', JSON.stringify([]))
            }


        },
        downloadPage(){

            // this.$router.push('/download')
            this.$router.push({path: `/download/${this.image}`})

        }

    },
    mounted() {
        this.createStorage()
        this.checkIsLoved()

    }

}

</script>