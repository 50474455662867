<template>
  <div class="home my-5">
    <div class="container">
      <iframe class="ads-banner" style='background-color: transparent; border-radius:10px' width="100%" height="100vh" scrolling="no" frameborder="0" allowtransparency="true" 
      marginheight="0" marginwidth="0" name="spot_id_10001820" src="https://a.adtng.com/get/10001820?ata=maxpet01"></iframe>
      <ImageGallery></ImageGallery>
      
    </div>
    <FilterBar></FilterBar>
  </div>
  

</template>

<script>

import ImageGallery from '@/components/ImageGallery.vue'
import FilterBar from '@/components/FilterBar.vue'

export default {
  name: 'HomeView',
  components: {
    'ImageGallery': ImageGallery,
    'FilterBar': FilterBar,
  },
  data() {
    return {
      index: null

    }
  }
}
</script>
