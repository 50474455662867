<template>
  <div @scroll="loadmore" class="columns is-multiline">
    <div v-for="(image, index) in files" :key="image.id" class="column is-one-quarter">
      <div class="image-box box" @click="toggleLightbox(index)"
        :style="`background-image:url('${this.apiUrl}/uploads/gallery/${image.id}/thumb/${image.name}')`">

        <!-- @click="toggleLightbox(index)" -->
        <!-- <div class="open-image-box"><a class="open-image" @click="toggleLightbox(index)"><i
                class="fa-solid fa-magnifying-glass-plus"></i></a></div> -->

        <InteractMenu v-on:click.stop :image="image.id"></InteractMenu>
      </div>

      <!-- <div class="image-box box"><iframe style='background-color: transparent;' width="100%" height="100vh" scrolling="no" frameborder="0" allowtransparency="true" 
        marginheight="0" marginwidth="0" name="spot_id_10001820" src="https://a.adtng.com/get/10001820?ata=maxpet01"></iframe></div> -->


    </div>

    <FsLightbox type="image" :toggler="toggler" :slide="slide" :sources="images" />

    <!-- <div v-if="files.length < 1" className="container" style="text-align: center;">
      <img class="img-fluid" src="images/loading.gif" alt="">
    </div> -->
    

  </div>
  <!-- <div v-if="files.length < 1" className="container" style="text-align: center;">
      <img class="img-fluid" src="images/loading.gif" alt="">
  </div> -->

</template>
<!-- <script src="httpss://ajax.googleapis.com/ajax/libs/jquery/1/jquery.js"></script>
<script src="httpss://cdnjs.cloudflare.com/ajax/libs/galleria/1.5.7/galleria.min.js"></script> -->
<script>

import FsLightbox from "fslightbox-vue/v3";
import InteractMenu from "@/components/InteractMenu.vue";
import debounce from 'lodash/debounce';

export default {
  components: { FsLightbox, InteractMenu },
  data() {

    return {
      slide: 0,
      files: [],
      images: [],
      toggler: false,
      isLoading: false,
      filter:'newest',
      annoucementLinks:[
        'https://landing.hentaiprosnetwork.com/?ats=eyJhIjoxMDkwODIyLCJjIjo2MjU5MTgzOCwibiI6MjUsInMiOjUzNywiZSI6ODk5NCwicCI6MTF9&atc=10001820_215064_25891_848610_HP_800194',
        'https://network.nutaku.net/images/lp/aeons-echo/4-character-selector-v2/1/?ats=eyJhIjoxMDkwODIyLCJjIjo2MjU5MTgzOCwibiI6MSwicyI6MSwiZSI6MTA5MDQsInAiOjJ9',
        'https://network.nutaku.net/images/lp/lust-goddess/thumb-survey/1/?ats=eyJhIjoxMDkwODIyLCJjIjo2MjU5MTgzOCwibiI6MSwicyI6MSwiZSI6MTA5MTMsInAiOjJ9',
        'https://network.nutaku.net/images/lp/aeons-echo/3-character-selector/1/?ats=eyJhIjoxMDkwODIyLCJjIjo2MjU5MTgzOCwibiI6MSwicyI6MSwiZSI6MTA4OTEsInAiOjJ9',
        'https://landing.hentaipros.com/?ats=eyJhIjoxMDkwODIyLCJjIjo2MjU5MTgzOCwibiI6MjUsInMiOjIyOCwiZSI6ODk5NiwicCI6Mn0='
      ]
    }

  },
  created() {

    this.host = this.apiUrl
    this.isLoading = true
    
    this.getImages(this.host, this.filter).then((i)=>{
      this.files = i
      this.isLoading = false
    })

  },
  mounted() {
    this.handleDebouncedScroll = debounce(this.handleScroll, 400);
    window.addEventListener('scroll', this.handleDebouncedScroll);
  },
  methods: {
    handleScroll() {

      this.isUserScrolling = (window.scrollY > 0);
      this.loadmore()

    },
    getImages: async (host, filter) => {

      let options = {
        headers: {
          'Content-Type': 'appication/json;charset=utf-8',
          'Access-Control-Allow-Origin': '*',
        },
        method: 'get',
      };

      let response = await fetch(`${host}/api/images/${filter}`, options);
      return await response.json();

    },

    getMoreImages: async (host, files, filter) => {

      let data = {
        images: files.map(function (i) {
          return i.id
        }),
        filter:filter
      }

      let options = {
        headers: {
          'Content-Type': 'appication/json;charset=utf-8',
          'Access-Control-Allow-Origin': '*',
        },
        method: 'post',
        body: JSON.stringify(data)
      };

      let response = await fetch(`${host}/api/images/load`, options);
      return await response.json();

    },
    openLightbox() {
      if (this.images.length > 0) {
        this.$refs.lightbox.open();
      }
    },
    toggleLightbox(item) {
      this.toggler = !this.toggler
      this.slide = item + 1

      this.openAnnoucement()

    },
    loadmore() {
      this.isLoading = true

      this.getMoreImages(this.apiUrl, this.files, this.filter).then(
        (i) => {
          this.files = [...this.files, ...i]
          this.isLoading = false
        })
    },
    openAnnoucement()
    {

      let length = this.annoucementLinks.length;

      let min    = Math.ceil(0)
      let max    = Math.floor(length)
      let ramdom = Math.floor(Math.random() * (max - min)) + min

      console.log(ramdom)

      let annoucement = this.annoucementLinks[ramdom];

      setTimeout(() => {

        window.open(annoucement, '_blank')
        
      }, 500);


    }


  },
  watch: {
    files: function (newVal) {

      let host = this.apiUrl

      this.images = newVal.map(function (img) {

        return `${host}/uploads/gallery/${img.id}/original/${img.name}`

      });

    },
    checkFilterChange:function(ev){

      this.filter = ev

      this.getImages(this.apiUrl, this.filter).then((i) =>{
        this.files = i
        this.isLoading = false
      })

    }

  },
  computed:{
    checkFilterChange(){
      return this.$store.getters.filter
    },
    apiUrl(){
      return this.$store.getters.apiUrl
    }
  }


}

</script>
