<template>
  <nav class="navbar bg-theme-5 is-fixed-top">
    <div class="navbar-brand">
      <a class="navbar-item" href="/">
        Futanari Site
      </a>

      <a class="navbar-item is-theme-03" href="/#/servers">Discord Servers</a>
      <a class="navbar-item is-theme-03" target="_blank" href="https://landing.hentaiprosnetwork.com/?ats=eyJhIjoxMDkwODIyLCJjIjo2MjU5MTgzOCwibiI6MjUsInMiOjUzNywiZSI6ODk5NCwicCI6MTF9&atc=10001820_215064_25891_848610_HP_800194">And Much more</a>
      <a class="navbar-item is-theme-03" href="/#/about">about</a>
      
    </div>
    
    <div id="navbarExampleTransparentExample" class="navbar-menu">

      <div class="navbar-end">

        <div class="navbar-item ">
          <div class="field is-grouped">
            <a class="button text-theme-1" href="" title="Update!" alt="Update!">
              <span class="icon">
                <i class="fa-solid fa-rotate"></i>
              </span>
            </a>



          </div>

        </div>
      </div>
    </div>


  </nav>
</template>

<script>
export default {
  name: 'NavBar',
  data() {
    return {
      dropDown: false
    }
  },
  methods: {
    isActive: function () {



    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
