import { createStore } from 'vuex'

export default createStore({
  state: {
    isAdult:false,
    filter:'newest',
    // apiUrl:process.env.API_DEV_URL
    // apiUrl:'http://localhost:8000'
    apiUrl:'https://data.futanari.site'
  },
  getters: {
    checkIsAdult(state){

      if(localStorage.getItem('isAdult')){
        return true
      }
      
      return state.isAdult

    },
    filter(state)
    {
      return state.filter
    },
    apiUrl(state){
      return state.apiUrl
    }
  },
  mutations: {

    isAdult(state){
      
      if(!localStorage.getItem('isAdult')){
        
        localStorage.setItem('isAdult',true)

      }
      
      state.isAdult = true
    },
    applyFilter(state, filter){

      state.filter = filter

    }
  },
  actions: {
  },
  modules: {
  }
})
