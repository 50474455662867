<template>
    <div class="box filter-bar">
        <div class="buttons is-justify-content-center">
            <button title="Newest" class="button is-small" @click="filter('newest')"
                :class="{ 'is-active': filtered == 'newest' }">
                <span class="icon is-small">
                    <i class="fa-solid fa-arrow-up-wide-short"></i>
                </span>
            </button>
            <button title="Oldtest" class="button is-small" @click="filter('oldtest')"
                :class="{ 'is-active': filtered == 'oldtest' }">
                <span class="icon is-small">
                    <i class="fa-solid fa-arrow-down-wide-short"></i>
                </span>
            </button>
            <button title="Random" class="button is-small" @click="filter('random')"
                :class="{ 'is-active': filtered == 'random' }">
                <span class="icon is-small">
                    <i class="fa-solid fa-shuffle"></i>
                </span>
            </button>

            <!-- <button @click="SelectTags" title="Tags" class="button is-small">
                <span class="icon is-small">
                    <i class="fa-solid fa-chevron-down"></i>
                </span>
                <span>tags</span>
            </button> -->

        </div>
    </div>
</template>
<script>
import Swal from 'sweetalert2'

export default {
    data() {
        return {
            filtered: 'newest',
            openTags: false,
            host: "futanari.site",
            tags:{

            }
        }
    },
    methods: {

        filter(f) {

            this.filtered = f
            this.$store.commit('applyFilter', this.filtered)
        },
        async SelectTags() {

            const { value: fruit } = await Swal.fire({
                title: "Filter by Tag",
                input: "select",
                inputOptions:this.tags,
                inputPlaceholder: "Select a tags",
                showCancelButton: true,
                inputValidator: (value) => {
                    return new Promise((resolve) => {
                        if (value === "oranges") {
                            resolve();
                        } else {
                            resolve("You need to select oranges :)");
                        }
                    });
                }
            });
            if (fruit) {
                Swal.fire(`You selected: ${fruit}`);
            }

        },
        getTags: async (host) => {

            let options = {
                headers: {
                    'Content-Type': 'appication/json;charset=utf-8',
                    'Access-Control-Allow-Origin': '*',
                },
                method: 'get',
            };

            let response = await fetch(`http://${host}/api/tags`, options);
            return await response.json();

        },

    },
    mounted(){

        // let self = this

        // this.getTags(this.host).then(function(t){

        //     self.tags = t

        // })

    }

}
</script>
