<template>
  <div class="about my-5">
    <div class="container">
      <iframe style='background-color: transparent;' width="100%" height="100vh" scrolling="no" frameborder="0" allowtransparency="true" marginheight="0" marginwidth="0" name="spot_id_10002800" src="https://a.adtng.com/get/10002800?ata=maxpet01"></iframe>

      <div class="columns is-multiline">
        <div v-for="server in servers" v-bind:key="server" class="column is-one-quarter">
          <div class="box">
            <article class="media">
              <figure class="media-left">
                <p class="image is-64x64">
                  <img :src="server.image" />
                </p>
              </figure>
              <div class="media-content">
                <div class="content">
                  <p>
                    <strong>{{ server.title }}</strong>
                  </p>
                </div>
                <nav class="level is-mobile">
                  <div class="level-left">
                    <a class="level-item" target="_BLANK" :href="server.link">
                      Join
                    </a>

                  </div>
                </nav>
              </div>
              <div class="media-right">
                <!-- <button class="delete"></button> -->
              </div>
            </article>

          </div>

        </div>
      </div>
      <iframe style='background-color: transparent;' width="100%" height="100vh" scrolling="no" frameborder="0" allowtransparency="true" 
      marginheight="0" marginwidth="0" name="spot_id_10001820" src="https://a.adtng.com/get/10001820?ata=maxpet01"></iframe>
    </div>
  </div>
</template>

<script>
export default {

  data() {

    return {

      servers: [
        {
          title: "[18+] Futanari Breeding",
          image: "https://cdn.discordapp.com/icons/777205184208044042/365bb26fa87995d7df3c9a9a240c76ba.jpg",
          link: "https://discord.gg/pyzh7p7Z"
        },
        {
          title: " 🔥 Elysian Desires 🔥 ",
          image: "https://cdn.discordapp.com/icons/1190163803170684998/d48f7dff6cf4fd56e7c65e7f7eb736e0.jpg",
          link: "https://discord.com/invite/snmCxZAGdJ"
        }
        ,
        {
          title: " DM ERP Futa  ",
          image: "https://cdn.discordapp.com/icons/1248035100667543604/b8afb981e1b825f889ca0a2fe013923c.jpg",
          link: "https://discord.com/invite/s96CsPS5hU"
        },
        {
          title: " 🌺Futanari Resort Islands🌺 ",
          image: "https://cdn.discordapp.com/icons/796786803990200321/a_c26426d38af5d5ce55860c498efb847d.jpg",
          link: "https://discord.com/invite/cjM5kDKWJy"
        }
        ,
        {
          title: "  [ESP] Culto Futanari  ",
          image: "https://cdn.discordapp.com/icons/855135749845155910/bd426ac0991677587e7599da2841ef90.jpg",
          link: "https://discord.com/invite/6dJD8c5gZF"
        }


      ]



    }

  }


}

</script>